<template>
  <div>
    <azkar-form
      :item="azkar"
      :submit="addZikr"
    />
  </div>
</template>
<script>
import AzkarForm from '@/common/components/azkar/AzkarForm.vue'
import Azkar from '@/common/compositions/azkar/AzkarApi'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'AddZikr',
  components: { AzkarForm },
  setup() {
    const { azkar, addAzkarRequest, prepareFormData } = Azkar()
    const { successfulOperationAlert } = handleAlerts()
    return {
      azkar, addAzkarRequest, prepareFormData, successfulOperationAlert,
    }
  },
  methods: {
    addZikr() {
      this.prepareFormData()
      return this.addAzkarRequest().then(() => {
        this.successfulOperationAlert('Zikr is added successfully')
        this.$router.push({ name: 'azkar-list' })
      })
    },
  },
}
</script>
<style lang="">

</style>
